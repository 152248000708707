import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './product.css'

const Product = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleActive = (index) => {
        setActiveIndex(index);
    };



    return (
        <div className='w-full min-h-screen px-10 py-5'>
            <div className='w-full'>
                <div className='w-full flex items-center justify-center flex-col gap-5 px-4 py-5'>
                    <h2 className='heading text-4xl text-4md text-[#e95d5d] font-semibold '>You May Like One Of Our Products</h2>
                    {/* <ul className='flex gap-5 overflow-x-auto' >
                <li onClick={() => handleActive(0)} className={`text-[13px] font-bold ${activeIndex === 0 ? 'active' : ''}`}><Link> Groundnut Kernels</Link></li>
                <li onClick={() => handleActive(1)} className={`text-[13px] font-bold ${activeIndex === 1 ? 'active' : ''}`}><Link> Blanched Peanuts</Link></li>
                <li onClick={() => handleActive(2)} className={`text-[13px] font-bold ${activeIndex === 2 ? 'active' : ''}`}><Link>Chickpeas</Link> </li>
                <li onClick={() => handleActive(3)} className={`text-[13px] font-bold ${activeIndex === 3 ? 'active' : ''}`}><Link>Roasted chickpeas</Link> </li>
                <li onClick={() => handleActive(4)} className={`text-[13px] font-bold ${activeIndex === 4 ? 'active' : ''}`}><Link>Red Chilli</Link> </li>
                <li onClick={() => handleActive(5)} className={`text-[13px] font-bold ${activeIndex === 5 ? 'active' : ''}`}><Link>Red Chilli Powder</Link> </li>
                <li onClick={() => handleActive(6)} className={`text-[13px] font-bold ${activeIndex === 6 ? 'active' : ''}`}><Link>Coriander Seeds</Link> </li>
                <li onClick={() => handleActive(7)} className={`text-[13px] font-bold ${activeIndex === 7 ? 'active' : ''}`}><Link> Coriander Powder</Link></li>
                <li onClick={() => handleActive(8)} className={`text-[13px] font-bold ${activeIndex === 8 ? 'active' : ''}`}><Link> Turmeric/Haldi</Link></li>
                <li onClick={() => handleActive(9)} className={`text-[13px] font-bold ${activeIndex === 9 ? 'active' : ''}`}><Link> Makhana</Link></li>
                <li onClick={() => handleActive(10)} className={`text-[13px] font-bold ${activeIndex === 10 ? 'active' : ''}`}><Link> Jaggery</Link></li>
            </ul> */}
                </div>
                <div className='product-container min-h-screen  gap-10'>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://img.freepik.com/free-photo/common-food-that-can-cause-allergic-reactions-people_23-2149870597.jpg?w=740" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Groundnut Kernels</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://img.freepik.com/free-photo/top-view-peanuts_23-2148452961.jpg?t=st=1709555787~exp=1709559387~hmac=0380aedc84a62c81709d76aad34a0ce539318f0c010188805d2152dfc03cc23c&w=740" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Blanched Peanuts</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://as2.ftcdn.net/v2/jpg/03/34/12/99/1000_F_334129914_pVoe2lwoU1LdJDWQFYLKcK3vefuOB9Ir.jpg" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Chickpeas</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://as1.ftcdn.net/v2/jpg/05/84/48/34/1000_F_584483427_TPbwbTKF1R7adIUYyNJfpd9hrJ6dOYys.jpg" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Roasted Chickpeas</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://as1.ftcdn.net/v2/jpg/03/24/74/64/1000_F_324746461_L22q6TrD6dCfYTF59TiIx5Wvj71pYVUj.jpg" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Red Chilli</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://as2.ftcdn.net/v2/jpg/04/73/28/71/1000_F_473287197_EMgf7j4kFoYYBnFGFe7G1fg0NKFl6TbB.jpg" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Red Chilli Powder</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://as2.ftcdn.net/v2/jpg/05/27/15/75/1000_F_527157569_LFsjbXYhUB3xBQJ0nhOBSPITfMFYU5gI.jpg" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Coriander Seeds</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://as1.ftcdn.net/v2/jpg/00/81/86/88/1000_F_81868817_cOOiXJaQVR0T5NxUplpyFhTnjDDDceT5.jpg" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Coriander Powder</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://as2.ftcdn.net/v2/jpg/03/24/30/05/1000_F_324300514_hD15ArkGQeBsNcNVSAGwLiMalwwK6qve.jpg" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Turmeric/Haldi</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://as2.ftcdn.net/v2/jpg/04/97/91/99/1000_F_497919973_IJCdtAdq93cdSvXbc10K2FHNQz1R6Nt0.jpg" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Makhana</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='productcard w-[250px] h-[330px] sm:w-auto  '>
                        <div className='w-full  h-[230px] flex flex-col items-center '>
                            <img className='w-full h-full rounded-xl object-center object-cover' src="https://img.freepik.com/free-photo/delicious-jaggery-still-life-composition_23-2149161614.jpg?t=st=1709557805~exp=1709561405~hmac=7ee9690eca8f654563fefd66222da1e6f17b0952bee43d2caa5586e5aa76d288&w=740" alt="im" />
                        </div>
                        <div className='w-full h-full '>
                            <h4 className='text-2xl font-bold px-2'>Jaggery</h4>
                            <div className='flex items-center justify-between '>
                                <ul className='flex items-center justify-center m-2'>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-outline"></ion-icon></li>
                                    <li><ion-icon name="star-half-outline"></ion-icon></li>

                                </ul>

                                <div> <Link className='px-3 text-[16px] rounded-xl py-1 bg-red-500 text-white '>Click Me</Link></div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default Product