import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Header.css'

const Header = () => {
    const[isopenMenu,setopenMenu] = useState(false)

    const onhandleChange = ()=>{
        setopenMenu(!isopenMenu)
    }
  return (
    <div className='main-container w-full h-full bg-[#415161] text-white py-4 px-10 font-sans'>
        <header className='flex items-center justify-between'>
            <div className='logo'>
                <h1 className='text-1xl text-ellipsis'><span className='text-2xl text-[#A3936C]'>Keska</span> Globle Trade</h1>
            </div>

            <nav className='navbar'>
                <ul className={`nav-links flex items-center justify-center gap-10 ${isopenMenu ? 'show' : ''}`}>
                    <li><Link>Home</Link></li>
                    <li><Link>About</Link></li>
                    <li><Link>Testimonial</Link></li>
                    <li><Link>Products</Link></li>
                    <li><Link>Contact</Link></li>
                </ul>
            </nav>
            {/* nav-links flex items-center justify-center gap-10 */}
            <div className='social-icon flex items-center justify-center gap-2 text-2xl '>
            <ion-icon name="logo-facebook"></ion-icon>
            <ion-icon name="logo-instagram"></ion-icon>
            </div>

            <div className='responsive'>
            <ion-icon onClick={onhandleChange} className='menu' name="menu"></ion-icon>   
            <ion-icon className='close' name="close-circle-sharp"></ion-icon>
            </div>
        </header>
    </div>
  )
}

export default Header

