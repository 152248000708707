
import './App.css';
import {BrowserRouter,Routes,Route, Router} from "react-router-dom";
import Header from './Component/Header/Header';
import Home from './Component/Home/Home';
import Footer from './Component/Footer/Footer';

function App() {
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
      <Route path='/' element={<Home/>} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
